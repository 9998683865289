 /* body {
  font-family: "Poppins", sans-serif;
  padding: 0;

  margin: 0;
  background-color: #f3f1f1;
  height: 100vh;
  overflow: hidden;
} */
 
.login-body {
  background-color: #2490eb30;
}
/* login-page-start */
.main-login {
  padding: 100px;
}

.login-page-bg {
  width: 550px;
  border-radius: 10px;
}

.brand-logo {
  background-color: #14457b;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.form-control {
  position: relative;
}




.login-inputs {
  padding: 40px 40px 40px 40px;
  background-color: #fff;
}

.login-inputs .form-floating {
  width: 413px;
}

.login-inputs .form-control {
  background-color: #ececec;
  border: none;
  margin-bottom: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.login-inputs .form-control:focus {
  box-shadow: none;
}
.login-inputs .input-group-text {
  height: 40px;
  border: none;
  background-color: #2490eb;
  padding: 0px 20px 0px 20px;
  font-size: 20px;
  z-index: 9999;
}

/* .btn-signin .btn-signin {
  width: 170px;
  height: 50px;
  border-radius: 5px;
  background-color: #14457b;
  color: white;
  border: none;
  font-weight: 500;
  font-size: 18px;
} */

.btn-forgot {
  background-color: #2490eb;
  padding: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 20px;
}

.btn-forgot .btn-primary {
  font-size: 20px;
  background-color: inherit;
  border: none;
  color: black;
  font-weight: 500;
}

/* login-page-end */

.header-sec {
  padding: 20px 20px 20px 30px;
  background-color: #14457b;
}

.brand-logo-patient img {
  width: 80%;
}

.input-search {
  width: 20%;
}

.search-icon {
  position: absolute;
  top: 30%;
  right: 6%;
  font-size: 14px;
  z-index: 999;
  color: #73685d;
}

.drop-arrow {
  position: absolute;
  top: 38%;
  right: 9%;
  font-size: 20px;
  z-index: 99999;
  color: black;
}

.facility-down .dropdown .btn-secondary {
  padding: 10px;
  text-align: left;
  background-color: #fff;
  color: #73685d;
  width: 100%;
  font-size: 12px;
}

.facility-down .dropdown-menu {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(0px, 68px);
  width: 100%;
  top: -8% !important;
}


.facility-down .dropdown {
  position: relative;
}
.facility-down .dropdown-toggle::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 0px;
  top: 12px;
  right: 7%;
  top: 44%;
  font-size: 18px;
}


.bell-icon {
  font-size: 14px;
  color: #fff;
}

.admin-name a {
  text-decoration: none;
  color: white;
  font-size: 14px;
}

.drop-icon {
  color: rgb(255, 255, 255);
  text-emphasis: none;
}


.search-icon-list {
  position: absolute;
  top: 36%;
  right: 9%;
  font-size: 18px;
  z-index: 999;
  color: black;
}

.input-search {
  height: 40px;
}

table {
  border: 1px #a39485 solid;
  font-size: .9em;
  box-shadow: 0 2px 5px rgba(0,0,0,.25);
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}

th {
  text-align: left;
  font-size: 14px;
}
  
thead {
  font-weight: bold;
  
}
  
 td, th {
  padding: 1em .5em;
  vertical-align: middle;
}
  
 td {
  font-size: 12px;
}

a {
  color: #73685d;
  font-size: 12px;
}
  
  
  table {
    position: relative; 
    padding-bottom: 0;
    border: none;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
  }
  
  thead {
   
    white-space: nowrap;
  }

.user-img {
  width: 40px;
  height: 40px;
}

.patient-info th {
  background-color: #14457b;
  color: #fff;
}

.patient-info tr:nth-child(even) {
  background-color: #ecf5ff;
}

.patient-info tr:nth-child(odd) {
  background-color: #c8e3ff;
}

.modal-header {
  background-color: #307bc4;
  color: white;
}

.modal-footer {
  background-color: #307bc4;
  color: white;
}
.modal-body {
  padding: 0px;
}
.btn-danger {
  background-color: white;
  color: #000;
  border: none;
}
/* patientinfo forms start */

.main-body {
  background-color: #fff;
  padding: 30px 20px 50px 20px;
}

.main-patientinfo {
  background-color: #ecf5ff;
  border-radius: 10px;
  padding: 15px 20px 40px 20px;
}
/* .main-patientinfo h1 {
  font-size: 30px;
  margin-bottom: 50px;
  font-weight: 600;
  margin-left: 30px;
} */


.personal-info {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
}


.form-two .btn-previous {
  border: none;
  background-color: inherit;
}

.form-two .btn-previous a {
  color: #0031df;
}

.select-location label {
  padding-bottom: 3px;
}
.select-location {
  margin-bottom: 14px;
}
/* patientinfo forms end */
/* patient-detail start */
.header-sec-two {
  padding: 6px 20px 6px 20px;
  background-color: #14457b;
}

.main-left {
  background-color: #ecf5ff;
  width: 300px;
  border-radius: 0px 30px 0px 0px;
}

.patient-detail-tabs .nav-tabs .nav-link.active {
  background-color: #14457b;
  color: #fff;
  border-bottom: 2px solid #2490eb;
}

li,
a {
  font-size: 12px;
}
h2,
h5,
h3,
h4,
h6 {
  font-size: 13px !important;
}
.patient-detail-tabs .nav-tabs .nav-link {
  color: black;

  font-size: 14px;
}

.left-nav {
  width: 300px;
}

.patient-data {
  background-color: #14457b;
  padding: 20px 20px 20px 20px;
  border-radius: 0px 30px 0px 0px;
}

.user-detail p {
  font-size: 12px;
  margin-bottom: 5px;
  color: white;
}

.user-icon li {
  list-style: none;
  padding: 0;
  margin-right: 20px;
}
.user-icon li a {
  text-decoration: none;
  color: #fff;
  font-size: 12px;
}

/* .user-icon i:hover {
  color: #ba2748;
} */
.user-detail ul {
  margin: 0px;
  padding: 0px;
}

.user-detail h5 {
  margin-bottom: 5px;
  color: #fff;
  font-size: 14px;
}

.user-detail span a {
  text-decoration: none;
  color: #61ff00;
  margin-bottom: 5px;
  font-size: 12px;
}
ul,
li,
a {
  text-decoration: none;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.user-bio li {
  border-bottom: 1px solid #d7e2ed;
  padding: 10px 0px 10px 20px;
}
.user-bio li:hover a {
  cursor: inherit;
}
.user-bio-links li span {
  font-weight: 300;
  color: black;
}

.user-bio-links li a {
  font-weight: 400;
  color: black;
  font-size: 12px;
}

.user-bio-dropdowns .btn-group {
  width: 100%;
}

.accordion {
  --bs-accordion-btn-icon: #fff;
}
.accordion-button {
  color: #ffffff;
  text-align: left;
  background-color: #14457b;
}
/* .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
} */

/* right-sec-start */

.right-sec {
  margin-left: 20px;
}

.right-linkstabs {
  background-color: #307bc4;
  border-radius: 5px;
}

.right-linkstabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border: none;
  font-size: 13px !important;
}

.right-linkstabs .dropdown {
  background-color: #e0eefc;
  border-radius: 10px;
  border: none;
}

.right-linkstabs .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #14457b;
  border-bottom: none;
}

.right-linkstabs .nav-tabs .nav-link {
  color: white;
  font-size: 14px;
  background-color: #307bc4;
}

.right-linkstabs .dropdown .btn-secondary {
  padding: 10px;
  text-align: left;
  background-color: #fff;
  color: #73685d;
  width: 100%;
  font-size: 12px;
}
.dropdown-item {
  font-size: 12px;
}

.right-linkstabs .menu-bar {
  font-size: 27px;
  color: #fff;
}

.Demographics .nav-link {
  background-color: transparent;
}
.Demographics {
  padding: 15px 20px 15px 30px;
  background-color: #ecf5ff;
  margin-bottom: 5px;
  border-radius: 0px 0px 0px 10px;
}

.Demographics ul li a {
  font-size: 20px;
  color: black;
  font-weight: 600;
}

.basic-bg {
  background-color: #307bc4;
  padding: 12px 10px;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}

.basic-bg h5 {
  margin: 0;
  font-size: 14px;
}

.basic-info {
  padding: 10px;
}

.demographics-cards {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  background-color: #fff;
  border-radius: 10px 10px 10px 10px;
  height: 100%;
}
.Demographics ul li a {
  font-size: 14px;
}
.basic-info li {
  margin-bottom: 5px;
}

.body-space-p {
  padding: 20px;
  background-color: #ecf5ff;
  overflow: auto;
  border-radius: 10px;
}

.basic-info li span {
  font-weight: 600;
  color: #5b5b5b;
}

.basic-info li a {
  font-weight: 300;
  color: #656565;
  font-size: 12px;
}

.btn-encounters-links {
  background-color: #14457b;
  padding: 5px;
  border: none;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
}

.msg-link a {
  text-decoration: underline;
  font-size: 16px;
  color: #656565;
  font-size: 12px;
}

/* .medication-links {
  padding: 15px 30px 15px 30px;
  background-color: #ecf5ff;
  margin-bottom: 5px;
  border-radius: 0px 0px 0px 10px;
} */

.medication-links .nav-item a {
  font-size: 14px;
  color: black;
  font-weight: 600;
}

.medication-links .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 5px;
  border-radius: 10px;
}

.Medications-btn {
  width: 20%;
}

.btn-group .btn-danger {
  background-color: #14457b;
  border: none;
  padding: 10px;
  border-radius: 10px;
}
.Medications-btn .dropdown-toggle {
  white-space: nowrap;
  background-color: #195085 !important;
}

.Medications-btn-add .dropdown-toggle {
  white-space: nowrap;
  background-color: #195085 !important;
}

.Medications-btn-add .btn-danger {
  font-size: 14px;
}
.Medications-btn-add .btn-danger {
  background-color: #307bc4;
  color: white;
}
/* .active-medications {
  padding: 10px 10px 10px;
  background-color: #307bc4;
  border-radius: 10px 10px 0px 0px;
  color: #fff;
} */

.active-medications h6 {
  margin: 0;
  font-size: 14px;
}

.active-medications .input-group {
  background-color: #fff;
  color: #000;
  width: 30%;
  border-radius: 10px;
}

.active-medications .input-group > .form-control {
  padding: 10px;
  border-radius: 10px;
}
.medication-anchor a {
  color: #fff;
  text-decoration: underline;
}

.medication-tables {
  background-color: #f6f6f6;
}

.medication-tables thead {
  background-color: #ecf5ff;
  color: black;
}

.thead-medications th {
  color: #626262;
  font-size: 14px;
  font-weight: 500;
}
.medication-tables tbody tr td {
  color: #595959;
  font-weight: 500;
  font-size: 12px;
}

.medication-view a {
  color: #1758ff;
  text-decoration: underline;
  font-weight: 300;
}

.medication-buttons .btn-primary {
  background-color: #14457b;
  border: none;
  font-size: 14px;
}

.medical-history {
  padding: 10px 20px 10px 30px;
  background-color: #307bc4;
  border-radius: 10px;

  color: #fff;
}
.medical-history h5 {
  margin: 0;
}

.medication-tables {
  background-color: #ecf5ff;
  padding: 20px;
}

.medical-history .input-group {
  background-color: #fff;
  color: #000;
  width: 30%;
  border-radius: 10px;
}

.medical-history .input-group .form-control {
  padding: 10px;
  border-radius: 10px;
}

/* medication-tab close */

/* problem-tabs start */

.problem-links {
  padding: 10px 30px 10px 30px;
  background-color: #ecf5ff;
  
}

.active-content h5 {
  background-color: #1870bb;
  padding: 14px;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
  z-index: 99999;
}

.problem-right-bg {
  border: 1px solid rgb(180, 180, 180);
  border-radius: 10px 10px 0px 0px;
}

/* .active-content {
  border: 1px solid rgb(180, 180, 180);
  height: 100vh;
  border-radius: 10px 10px 0px 0px;
} */
.problems-bg-add {
  background-color: #1870bb;
  padding: 10px;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}

.problem-search-feild .input-group {
  width: 100%;
}

.problem-search-feild .input-group .form-control {
  border: 1px solid #c7c8c9;
  padding: 10px;
  border-radius: 10px;
}

.problem-content .nav-link {
  margin-right: 10px;
}

.no-arrow-dropdown:after {
  display: none;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #307bc4;
}

.patient-detail-tabs .nav-tabs .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  height: 100%;
}
.patient-detail-tabs .nav-tabs li .fa-times {
  background-color: #ba2748;
  height: 16px;
  width: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-size: 12px;
  border-radius: 3px;
}

.patient-detail-tabs .nav-link {
  background-color: #c8e3ff;
}

.form-check-label {
  font-size: 12px;
}
.patient-detail-tabs .nav-tabs .nav-link.active .fa-times {
  background-color: white;
  color: #002548;
}

.btn-primary {
  color: #fff;
  background-color: #14457b;
  border-color: #0f3258;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2490eb;
  border-color: #1870bb;
}

.problem-right-bg ul li a {
  color: #fff;
  font-size: 14px;
}

.add-problem-link a {
  color: #fff;
  font-size: 14px;
}

.form-diagnosis {
  width: 90%;
}

.diagnosis-btn .btn-secondary {
  background-color: #e0eefc;
  color: #000;
  margin-top: 6px;
  border-radius: 10px;
  padding: 10px;
  width: 130px;
  font-size: 14px;
}


/* problem tab close */
/*demographics tab start*/
/* .demo-bg {
  padding: 20px 30px 20px 30px;
  background-color: #ecf5ff;
  border-radius: 0px 0px 0px 10px;
  margin-bottom: 5px;
} */

.demo-main-patient {
  background-color: #ecf5ff;

  border-radius:0px 0px 10px 10px;
  padding: 5px 20px 20px 20px;
}

.demo-personal-info .form-control {
  height: 65px;
  border: none;
  background-color: #fbfbfb;
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.demo-personal-info {
  background-color: #fff;
  padding: 20px 20px;
  border-radius: 10px;
}

.demo-bg .nav-tabs .nav-link {
  background-color: transparent;
  border: 1px solid #4d4d4d;
  padding: 10px;
  margin-right: 40px;
  border-radius: 10px;
  width: 120px;
  font-size: 12px;
}

.demo-bg .nav-tabs .nav-link.active {
  background-color: #14457b;
  color: white;
}

.demo-bg .nav-tabs {
  border-bottom: none;
}
/*demographics tab close*/
/* insurance tab start */



h5 {
  margin: 0 !important;
}

.insurance-heading ul li a {
  text-decoration: underline;
  color: white;
}

.insurance-data {
  min-height: 250px;
}

.insurance-data ul li a {
  color: #ae4f4d;
  font-weight: 500;
  font-size: 14px;
}

.input-group-prepend i {
  font-size: 1rem;
}

.insurance-search .form-floating {
  width: 92%;
  border-radius: 0px 0px 10px 10px;
}

.insurance-search .form-control {
  border: 1px solid #686868;
}
.insurance-search .input-group-text {
  border: 1px solid #686868;
  background-color: #ffffff;
  border-right: none;
}
.insurance-p {
  padding: 20px 30px 20px 30px;
  background-color: #ecf5ff;
}
.border-bottom {
  border-bottom: 1px solid #686868 !important;
}

.insurance-table thead {
  color: #686868;
}
/* insurance tab close */
/* Contact tab start */
.content-heading {
  border-radius: 16px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.content-heading h6 a {
  font-weight: 600;
  color: #307bc4;
  font-size: 14px;
}
.contact-link li a {
  color: #ff0000 !important;
  padding: 20px;
  font-size: 12px;
}

.accordion-item {
  font-size: 12px;
}

.accordion-button {
  font-size: 14px;
  padding: 0.6rem 1.25rem;
}
.accordion-button:focus {
  box-shadow: none;
}

.contact-feilds {
  padding: 20px;
}

/* Contact tab Close */

/* setting-tab-start */

.setting-bg {
  background-color: #ddecfc;
  padding: 20px 10px 20px 10px;
  border-radius: 10px;
  height: 100vh;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.nav-pills .nav-link {
  font-size: 14px;
}

.setting-bg .nav-link {
  text-align: left;
 
  color: rgb(0, 0, 0);
  background-color: #ddecfc;
}

.map-content .form-control {
  border: none;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.map-content h5 {
  margin: 0;
}

.map-bg {
  padding: 10px 20px 10px 20px;
  background-color: #307bc4;
  border-radius: 10px 10px 0px 0px;
}
.map-bg h5 {
  color: #fff;
}
.map-border {
  border: 1px solid #000;
  border-top-color: transparent;
  border-radius: 0px 0px 10px 10px;
  padding: 10px;
}

.preview-links .nav-tabs .nav-link {
  background-color: transparent;
  border: 1px solid #4d4d4d;
  padding: 10px;
  margin-right: 15px;
  border-radius: 10px;
  width: 120px;
  height: auto;
}

.facilty-links .nav-item .nav-link {
  background-color: #a6cffa;
  color: black;
  margin-right: 20px;
}

.facilty-links .nav-tabs .nav-link.active {
  background-color: #14457b;
  color: #fff;
}

.facilty-links .nav-tabs .nav-link:hover {
  border-color: transparent;
}

/* .myprofile-bg {
  padding: 20px;
  background-color: #ecf5ff;
  border-radius: 10px;
}

.myprofile-bg h5 {
  font-family: 600;
  color: #000;
  margin-bottom: 20px;
} */

/* .electric-forms {
  padding: 20px;
  background-color: #ddecfc;
  border-radius: 10px;
}

.electric-btn .btn-electric {
  border: 1px solid #686868;
  border-radius: 10px;
  padding: 8px;
} */

.security-content {
  padding: 20px;
  background-color: #ecf5ff;
  border-radius: 10px;
}

.security-content h5 {
  font-weight: 600;
}

.password-forms {
  padding: 20px;
  background-color: #ecf5ff;
  border-radius: 10px;
}

.password-forms h5 {
  font-weight: 600;
}

.btn-close {
  border: 0;
  border-radius: 0.25rem;
  opacity: 1;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #14457b;
}

.contact-bg-sec {
  background-color: #ecf5ff;
  padding: 5px 20px 20px 20px;
}

/* .active-bg-links {
  background-color: #ecf5ff;
  padding: 20px 10px 20px 10px;
  border-radius:0px 0px 10px 10px;
} */

.map-bg-sec {
  background-color: #ecf5ff;
  padding: 20px;
}



.bg-contact {
  background-color: #307bc4;
  border-radius: 8px 8px 0px 0px;
  z-index: 9999;
  color: #fff;
}

.w-300 {
  width: 300px;
}

.page-heading {
  margin-top: 10px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px;
  background-color: #ecf5ff;
}

.page-heading h5 {
  font-size: 1rem;
  display: inline-block;
  border-bottom: 2px solid #14457b;
  padding-bottom: 14px;
}

/*custom scroll*/
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

.bed-mapping-links {
  background-color: #c8e3ff;
  padding: 20px;
  border-radius: 10px;
}
.bed-mapping-links .nav-tabs .nav-link {
  margin-right: 20px;
  padding: 15px;
  width: 160px;
  border: 1px solid black;
  border-radius: 10px;
}

.bed-mapping-links .nav-tabs .nav-link.active {
  background-color: #14457b;
  color: #fff;
  border-bottom: 2px solid #2490eb;
}

.bed-mapping-headings {
  background-color: #1870bb;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  color: white;
}

.popover-header {
  background-color: #1870bb;
  padding: 10px;
  color: white;
}



.bs-popover-bottom > .popover-arrow::after {
  border-bottom-color: #1870bb;
}

.bed-user-detail h5,
p {
  color: #857878;
  margin: 0px;
}

.bed-user-detail h5 {
  color: #000;
}

.bed-user-detail p span {
  color: #000;
}

.bs-popover-bottom .popover-header::before {
  border-bottom: 1px solid #1870bb;
}

.popover-body {
  background-color: #e9e9e9;
  padding: 20px;
}

.bed-user-img img {
  border: 1px solid black;
  border-radius: 5px;
}

.bed-icon {
  font-size: 30px;
}

.bed-btn .btn-success {
  background: #1870bb;
  width: 80px;
}

.bed-maping-bg {
  background-color: #fff;
  padding: 20px;
}

.bed-two-btn .btn-success {
  background-color: #a6cffa;
  color: #000;
  width: 80px;
}

.bed-two-btn .bed-icon {
  color: #b89a00;
}

.bed-three-btn .btn-success {
  background-color: #a6cffa;
  color: #000;
  width: 80px;
}

.bed-three-btn .bed-icon {
  color: #ff0000;
}

.bed-four-btn .btn-success {
  background-color: #a6cffa;
  color: #000;
  width: 80px;
}

.bed-four-btn .bed-icon {
  color: #36b413;
}
/* allergy-tab-start */
/* .Allergy-heading {
  background-color: #ecf5ff;
  padding: 20px 30px 20px 30px;
  border-radius: 0px 0px 10px 10px;
} */

/* .Allergies-links {
  padding: 15px;
  background-color: #1870bb;
  border-radius: 10px 10px 0px 0px;
} */

.Allergies-links .form-check-label {
  color: #fff;
}

.allergy-table {
  padding: 15px;
  border-bottom: 1px solid rgb(128, 128, 128);
}

.allergy-table table {
  box-shadow: none;
}

.allergy-table thead {
  color: #3d3d3d;
}

.allery-header {
  padding: 10px;
  background-color: #307bc4;
}

.allergy-model-content {
  width: 750px;
}

.allergy-body {
  padding: 20px;
}

.allergy-footer-btn {
  background-color: #fff;
  border-top: none;
}

.scroll-view{
  width: 100%;
}


.maintenance-bg-profile{
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #A4A4A4;
}
.border-maintenance{
  border: 1px solid #A5A5A5;
  background-color: #fff;

}
.maintenance-container{
  background-color: #fff;
  width: 600px;
  padding: 20px;
 
}
.maintenance-img img{
border-radius: 50% ;
}
.avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50% ;
}

.circle-maintenance{
  font-size: 10px;
  
}
.btn-notification:hover{
  border: 1px solid #195085;
  background-color: #195085;
  color: #fff;
}
.btn-notification{
  width: 120px;
 padding: 5px;
  border: 1px solid #A5A5A5;
  background-color: inherit;
  border-radius: 50px;
  font-size: 14px;
}
.noti-btn {
  background-color:#e0eefc ;
  padding: 13px;
  width: 180px;
  border-radius: 10px;

}
.notification-bg{
  border: 1px solid #A5A5A5;
  padding: 10px;
  background-color: #fff;
  width: 270px;
  min-height: 570px;
border-radius: 10px;

 

}

.user-maintenance-positions-right{
  position: sticky;
  left: 67%;
  top: 0%;
  
}
@media (max-width:1500px) {
  .user-maintenance-positions-right{
    position: sticky;
    left: 72%;
    top: 0%;
    
  }
}
@media (max-width:1400px) {
  .user-maintenance-positions-right{
    position: sticky;
    left: 74%;
    top: 0%;
    
  }
}
@media (max-width:1300px) {
  .user-maintenance-positions-right{
    position: sticky;
    left: 75%;
    top: 0%;
    
  }
}
@media (max-width:1200px) {
  .user-maintenance-positions-right{
    position: sticky;
    left: 77%;
    top: 0%;
    
  }
}

@media (max-width:1100px) {
  .user-maintenance-positions-right{
    position: sticky;
    left: 85%;
    top: 0%;
    
  }
}

@media (max-width:1040px) {
  .user-maintenance-positions-right{
    position: sticky;
    left: 80%;
    top: 0%;
    
  }
}

.user-maintenance-positions-right li{
  font-size: 11px;
}
.maintenance-scroll{
  overflow: auto;
  height: 100vh;
}
.tweet-maintenance{
  width: 558px;
  height: 400px;
  margin: 10px auto;
  
}
.tweet-maintenance video{
 width: 100%;
 height: 100%;
}
.user-maintanance-bg{
  background-color: #195085;
  height: 80px;
  border-radius: 10px 10px 0px 0px;
  position: relative;
}

.img-maintenance-p{
  position: absolute;
  top: 63%;
  left: 38%;
}
.maintenance-links{
  padding: 10px 20px 10px 20px;
}
.maintenance-links li{
margin-bottom: 10px;
}

.user-maintenance-content{
  border: 1px solid #A5A5A5;
  background-color: #E0EEFC;
  width: 270px;
 min-height: 580px;
border-radius: 10px;

position: sticky;
left: 12%;


}
.tweet-maintenance img{
width: 100%;
object-fit: cover;
height: 100%;
}
.maintanance-social{

  background-color: #E0EEFC;
  
  padding: 20px;
  padding-left: 0px;
}
.like-icon{
  background-color: #C6DDF3;
  padding: 20px;
  font-size: 14px;
  color: #000;
}

.check-icon{
  padding: 20px;
  font-size: 14px;
  border-right: 1px solid #cacaca;
  color: #000;
}
.comment-icon{
  padding: 20px;
  font-size: 14px;
  border-right: 1px solid #cacaca;
  color: #000;
}
/* CSS code in PatientDetail.css */
.floating-button {
  position: fixed;
  background-color: #fff;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  z-index: 1000;
}
.p-float-label:has(input:focus) label, .p-float-label:has(input.p-filled) label, .p-float-label:has(input:-webkit-autofill) label, .p-float-label:has(textarea:focus) label, .p-float-label:has(textarea.p-filled) label, .p-float-label:has(.p-inputwrapper-focus) label, .p-float-label:has(.p-inputwrapper-filled) label {
  top: 0.6rem;
  font-size: 12px;
}
/* .p-float-label label{
  margin-top: 0.6rem;
} 
 .p-float-label:has(.p-inputwrapper-filled) label {
  top: 12px;
  font-size: 12px;
}  */
.btn-danger:hover .header-icon-ff{
  color: #fff !important;
  transition: .3s ease;
}

.patient-info th{
  font-size: 12px;
}

.css-1ex1afd-MuiTableCell-root{
  font-size: 12px;
}

.css-1ex1afd-MuiTableCell-root h6{
  font-size: 12px;
}

.w-patient-add .form-select{
  font-size: 13px;
}
.btn-success{
  font-size: 13px;
}
.btn-outline-success{
  font-size: 13px;
}
.p-inputtext{
  font-size: 12px !important;
}

.main-patientinfo h1{
  font-size: 13px;
}
h4{
  font-size: 12px;
}
th{
  font-size: 12px;
}
td{
  font-size: 12px;
}
.btn-primary{
  font-size: 13px;
}
.btn-discharge{
  font-size: 13px;
}
.nav-link{
  font-size: 13px;
}
.btn{
  font-size: 13px;
}
/* .form-floating>.form-control:not(:placeholder-shown)~label:after{
  height: 50px !important;
  width: 110% !important;
  background-color: #fff !important;
}
.form-floating>label {
  height: 30px;
  width: 115%;
} */