
.login-body{
    background-color: #7ebbfa30;
    height: 100vh
}
/* login-page-start */
.main-login{
    padding: 100px;
    }
    
    .login-page-bg{
        width: 550px;
        border-radius: 10px;
    }
    
    .brand-logo{
        background-color: #1b538b;
        padding: 30px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      
    }
    
    .form-control{
        position: relative;
    }
    
    
    .login-inputs{
        padding: 40px 40px 40px 40px;
        background-color: #fff;
        
    }
    
    .login-inputs .form-floating{
        width: 413px;
    }
    
    
    .login-inputs .form-control{
        background-color: #ececec;
        border: none;
        margin-bottom: 40px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
       
    }
    
    .login-inputs .form-control:focus{
        box-shadow:none
    }
    .login-inputs .input-group-text{
        height: 58px;
        border: none;
        background-color: #7ebbfa;
        padding: 0px 20px 0px 20px;
        font-size: 20px;
        z-index: 9999;
        
    }
    
    .btn-signin .btn-signin{
    width: 170px;
    height: 50px;
    border-radius: 5px;
        background-color: #1b538b;
        color: white;
    border: none;
    font-weight: 500;
    font-size: 18px;
    }
    
    .btn-forgot{
        background-color: #7ebbfa;
        padding: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        font-size: 20px;
    
    }
    
    .btn-forgot .btn-primary{
        font-size: 20px;
        background-color: inherit;
        border: none;
        color:black;
        font-weight: 500;
    }
    
    /* login-page-end */
