.right-sec{
    margin-left: 20px;
    width: 100%;
}
.security-content{

    padding: 20px;
    background-color: #ecf5ff;
    border-radius: 10px;

}

.security-content h5{
font-weight: 600;
}

.security-content h6{
margin-bottom: 5px;
margin-top: 5px;
}

.security-content p{
    margin-bottom: 5px;
    margin-top: 5px;
    }