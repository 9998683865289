.demographics-cards {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  background-color: #fff;
  border-radius: 10px 10px 10px 10px;
  height: 100%;
}
.basic-bg {
  background-color: #307bc4;
  padding: 12px 10px;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}
.basic-bg h5 {
  margin: 0;
  font-size: 14px;
}

.basic-info {
  padding: 10px;
}
.basic-info li {
  margin-bottom: 5px;
}
.basic-info li span {
  font-weight: 600;
  color: #5b5b5b;
}
.basic-info li a {
  font-weight: 300;
  color: #656565;
  font-size: 12px;
}
.button{
    color: #fff;
}
