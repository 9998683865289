body {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;

  height: 100%;
  overflow: auto;
}
.side-container {
  max-height: 100vh;
  overflow-y: auto;
}
.login-body {
  background-color: #2490eb30;
}
/* login-page-start */
.main-login {
  padding: 100px;
}

.login-page-bg {
  width: 550px;
  border-radius: 10px;
}

.brand-logo {
  background-color: #14457b;
  padding: 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.wound-height .form-control {
  height: 40px !important;
}

.form-control {
  position: relative;
}
/* Tablet menu icon */
.tablet-menu-icon {
  display: none; /* Hide the menu icon by default */
  background-color: #0f3258;
}

/* Media query for tablet-sized screens */
@media (max-width: 992px) {
  /* Show the menu icon on tablet-sized screens */
  .tablet-menu-icon {
    display: block;
    margin-left: 5px;
    color: white;
    width: 37px;
    z-index: 999;
    cursor: pointer;
    margin-top: -8px;
    /* background-color: #fff; */
    /* padding: 10px; */
    /* border-radius: 10px; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
  @media (max-width: 992px) {
    .main-left {
      position: fixed;
      z-index: 99999;
    }
  }
  .main-left-settings {
    width: 300px;
  }
  .right-sec {
    margin-left: 0 !important;
  }
}

@media (max-width: 768px) {
  .main-div {
    max-height: 85vh !important;
    overflow: auto;
  }
}
.settings-right {
  margin-left: 20px;
}
.scroll-view {
  overflow-x: auto;
  width: 100%;
}
/* .tablet-menu-icon{
 opacity: 0;
        }
    

        @media all and (max-width: 768px){
    .tablet-menu-icon{
        opacity: 100;
        z-index:9999 ;
    }
} */

.login-inputs {
  padding: 40px 40px 40px 40px;
  background-color: #fff;
}

.login-inputs .form-floating {
  width: 413px;
}

.form-floating > label {
  font-size: 13px;
  padding-top: 1rem !important;
}

.login-inputs .form-control {
  background-color: #ececec;
  border: none;
  margin-bottom: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.login-inputs .form-control:focus {
  box-shadow: none;
}
.login-inputs .input-group-text {
  height: 40px;
  border: none;
  background-color: #2490eb;
  padding: 0px 20px 0px 20px;
  font-size: 20px;
  z-index: 9999;
}

.btn-signin .btn-signin {
  width: 150px;
  height: 49px;
  border-radius: 5px;
  background-color: #14457b;
  color: white;
  border: none;
  font-weight: 500;
  font-size: 18px;
}

.btn-forgot {
  background-color: #2490eb;
  padding: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 20px;
}

.btn-forgot .btn-primary {
  font-size: 20px;
  background-color: inherit;
  border: none;
  color: black;
  font-weight: 500;
}

/* login-page-end */

.header-sec {
  padding: 20px 20px 20px 30px;
  background-color: #14457b;
}

.left-nav-w {
  width: 300px;
}
.brand-logo-patient img {
  width: 50%;
}

.input-search {
  width: 20%;
}

.input-search-document {
  width: 25%;
}
.search-icon {
  position: absolute;
  top: 30%;
  right: 6%;
  font-size: 14px;
  z-index: 999;
  color: #73685d;
}

.drop-arrow {
  position: absolute;
  top: 38%;
  right: 9%;
  font-size: 20px;
  z-index: 99999;
  color: black;
}

.facility-down .dropdown .btn-secondary {
  padding: 10px;
  text-align: left;
  background-color: #fff;
  color: #73685d;
  width: 100%;
  font-size: 12px;
}

.facility-down .dropdown-menu {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(0px, 68px);
  width: 100%;
  top: -8% !important;
}

.form-floating > .form-select {
  padding-top: 0.9rem;
  padding-bottom: 0;
  font-size: 12px;
}

.form-floating > .form-control::placeholder {
  line-height: 1rem;
}

.form-floating > .form-control:focus ~ label::after {
  background-color: inherit !important ;
}

.form-floating > label {
  top: -3px;
}
.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
  background-color: inherit;
}
.form-floating > .form-select ~ label::after {
  background-color: inherit;
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
  background-color: inherit !important;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(2.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(2.8rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 2rem;
  padding-bottom: 0.625rem;
}

.personal-info .form-floating > .form-control {
  border: none;
  background-color: #f9f9f9;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  font-size: 12px;
}

.facility-down .dropdown {
  position: relative;
}
.facility-down .dropdown-toggle::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 0px;
  top: 12px;
  right: 7%;
  top: 44%;
  font-size: 18px;
}

.bell-icon {
  font-size: 14px;
  color: #fff;
}

.admin-name a {
  text-decoration: none;
  color: white;
  font-size: 14px;
}

.drop-icon {
  color: rgb(255, 255, 255);
  text-emphasis: none;
}

.search-icon-list {
  position: absolute;
  top: 36%;
  right: 9%;
  font-size: 18px;
  z-index: 999;
  color: black;
}

.input-search {
  height: 40px;
}

table {
  border: 1px #a39485 solid;
  font-size: 0.9em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}

th {
  text-align: left;
  font-size: 14px;
}

thead {
  font-weight: bold;
}

td,
th {
  padding: 1em 0.5em;
  vertical-align: middle;
}

td {
  font-size: 12px;
}

a {
  color: #73685d;
  font-size: 12px;
}

table {
  position: relative;
  padding-bottom: 0;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

thead {
  white-space: nowrap;
}

.user-img {
  width: 40px;
  height: 40px;
}

.patient-info th {
  background-color: #14457b;
  color: #fff;
}

.patient-info tr:nth-child(even) {
  background-color: #ecf5ff;
}

.patient-info tr:nth-child(odd) {
  background-color: #c8e3ff;
}

.modal-header {
  background-color: #307bc4;
  color: white;
}

.modal-footer {
  background-color: #307bc4;
  color: white;
}
.modal-body {
  padding: 0px;
}

.btn-danger {
  --bs-btn-active-bg: #0f3258;
}
.btn-danger {
  background-color: white;
  color: #000;
  border: none;
}
/* patientinfo forms start */

.main-body {
  background-color: #fff;
  padding: 30px 20px 50px 20px;
}

.main-patientinfo {
  background-color: #ecf5ff;
  border-radius: 10px;
  padding: 40px 20px 40px 20px;
}
.main-patientinfo h1 {
  font-size: 20px;
  margin-bottom: 25px;
  font-weight: 600;
}

.form-control {
  font-size: 12px;
}

.form-floating > .form-select {
  height: 40px !important;
  line-height: 1.25;
}

.form-floating > label {
  font-size: 13px;
  padding-top: 0.7rem;
}
.personal-info .form-floating > .form-select {
  padding-top: 1.3rem;
  padding-bottom: 0.225rem;
  border: none;
  background-color: #f9f9f9;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  font-size: 12px;
}

.personal-info {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
}

@media (max-width: 1400px) {
  .personal-info {
    overflow: auto;
    max-height: 450px;
  }
}

.personal-info h3 {
  margin-bottom: 30px;
  font-size: 16px;
}

.form-two .btn-previous {
  border: none;
  background-color: inherit;
}

.form-two .btn-previous a {
  color: #0031df;
}

.select-location label {
  padding-bottom: 3px;
}
.select-location {
  margin-bottom: 14px;
}
/* patientinfo forms end */
/* patient-detail start */
.header-sec-two {
  padding: 12px 20px 12px 20px;
  background-color: #14457b;
}

.main-left {
  background-color: #ecf5ff;
  width: 300px;
  border-radius: 0px 30px 0px 0px;
}

.patient-detail-tabs .nav-tabs .nav-link.active {
  background-color: #14457b;
  color: #fff;
  border-bottom: 2px solid #2490eb;
}

li,
a {
  font-size: 12px;
}
h2,
h5,
h3,
h4,
h6 {
  font-size: 14px;
}
.patient-detail-tabs .nav-tabs .nav-link {
  color: black;

  font-size: 14px;
}

.left-nav {
  width: 300px;
}

.patient-data {
  background-color: #14457b;
  padding: 40px 20px 40px 20px;
  border-radius: 0px 30px 0px 0px;
}

.user-detail p {
  font-size: 12px;
  margin-bottom: 5px;
  color: white;
}

.user-icon li {
  list-style: none;
  padding: 0;
  margin-right: 20px;
}
.user-icon li a {
  text-decoration: none;
  color: #fff;
  font-size: 12px;
}

.user-icon i:hover {
  color: #2490eb;
}
.user-detail ul {
  margin: 0px;
  padding: 0px;
}

.user-detail h5 {
  margin-bottom: 5px;
  color: #fff;
  font-size: 14px;
}

.user-detail span a {
  text-decoration: none;
  color: #61ff00;
  margin-bottom: 5px;
  font-size: 12px;
}
ul,
li,
a {
  text-decoration: none;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.user-bio li {
  border-bottom: 1px solid #d7e2ed;
  padding: 10px 0px 10px 20px;
}
.user-bio li:hover a {
  cursor: inherit;
}
.user-bio-links li span {
  font-weight: 300;
  color: black;
}

.user-bio-links li a {
  font-weight: 400;
  color: black;
  font-size: 12px;
}

.user-bio-dropdowns .btn-group {
  width: 100%;
}

.accordion {
  --bs-accordion-btn-icon: #fff;
}
.accordion-button {
  color: #ffffff;
  text-align: left;
  background-color: #14457b;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/* right-sec-start */

.right-sec {
  margin-left: 20px;
}

.right-linkstabs {
  background-color: #307bc4;
  border-radius: 5px;
}

.right-linkstabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border: none;
}

.right-linkstabs .dropdown {
  background-color: #e0eefc;
  border-radius: 10px;
  border: none;
}

.right-linkstabs .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #14457b;
  border-bottom: none;
}

.right-linkstabs .nav-tabs .nav-link {
  color: white;
  font-size: 14px;
  background-color: #307bc4;
}

.right-linkstabs .dropdown .btn-secondary {
  padding: 10px;
  text-align: left;
  background-color: #fff;
  color: #73685d;
  width: 100%;
  font-size: 12px;
}
.dropdown-item {
  font-size: 12px;
}

.right-linkstabs .menu-bar {
  font-size: 27px;
  color: #fff;
}

.Demographics .nav-link {
  background-color: transparent;
}
.Demographics {
  padding: 15px 20px 15px 30px;
  background-color: #ecf5ff;
  margin-bottom: 5px;
  border-radius: 0px 0px 0px 10px;
}

.Demographics ul li a {
  font-size: 20px;
  color: black;
  font-weight: 600;
}

.basic-bg {
  background-color: #307bc4;
  padding: 12px 10px;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}

.basic-bg h5 {
  margin: 0;
  font-size: 14px;
}

.basic-info {
  padding: 10px;
}

.demographics-cards {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  background-color: #fff;
  border-radius: 10px 10px 10px 10px;
  height: 100%;
}
.Demographics ul li a {
  font-size: 14px;
}
.basic-info li {
  margin-bottom: 5px;
}

.body-space-p {
  padding: 20px;
  background-color: #ecf5ff;
  overflow: auto;
  border-radius: 10px;
}

.basic-info li span {
  font-weight: 600;
  color: #5b5b5b;
}

.basic-info li a {
  font-weight: 300;
  color: #656565;
  font-size: 12px;
}

.btn-encounters-links {
  background-color: #14457b;
  padding: 5px;
  border: none;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
}

.btn-encounters-links:hover {
  background-color: #2490eb;
}

.msg-link a {
  text-decoration: underline;
  font-size: 16px;
  color: #656565;
  font-size: 12px;
}

.medication-links {
  padding: 15px 30px 15px 30px;
  background-color: #ecf5ff;
}

.medication-links .nav-item a {
  font-size: 14px;
  color: black;
  font-weight: 600;
}

.medication-links .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 5px;
  border-radius: 10px;
}

.Medications-btn {
  width: 20%;
}

.btn-group .btn-danger {
  background-color: #14457b;
  border: none;
  padding: 10px;
  border-radius: 10px;
}
.Medications-btn .dropdown-toggle {
  white-space: nowrap;
  background-color: #195085 !important;
}

.Medications-btn-add .dropdown-toggle {
  white-space: nowrap;
  background-color: #195085 !important;
}

.Medications-btn-add .btn-danger {
  font-size: 14px;
}
.Medications-btn-add .btn-danger {
  background-color: #307bc4;
  color: white;
}
.active-medications {
  padding: 10px 10px 10px;
  background-color: #307bc4;

  color: #fff;
}

.active-medications h6 {
  margin: 0;
  font-size: 14px;
}

.active-medications .input-group {
  background-color: #fff;
  color: #000;
  width: 30%;
  border-radius: 10px;
}

.active-medications .input-group > .form-control {
  padding: 10px;
  border-radius: 10px;
}
.medication-anchor a {
  color: #fff;
  text-decoration: underline;
}

.medication-tables {
  background-color: #f6f6f6;
}

.medication-tables thead {
  background-color: #ecf5ff;
  color: black;
}

.thead-medications th {
  color: #626262;
  font-size: 14px;
  font-weight: 500;
}
.medication-tables tbody tr td {
  color: #595959;
  font-weight: 500;
  font-size: 12px;
}

.medication-view a {
  color: #1758ff;
  text-decoration: underline;
  font-weight: 300;
}

.medication-buttons .btn-primary {
  background-color: #14457b;
  border: none;
  font-size: 14px;
}

.medical-history {
  padding: 10px 20px 10px 30px;
  background-color: #307bc4;
  border-radius: 10px;

  color: #fff;
}
.medical-history h5 {
  margin: 0;
}

.medication-tables {
  background-color: #ecf5ff;
  padding: 20px;
}

.medical-history .input-group {
  background-color: #fff;
  color: #000;
  width: 30%;
  border-radius: 10px;
}

.medical-history .input-group .form-control {
  padding: 10px;
  border-radius: 10px;
}

/* medication-tab close */

/* problem-tabs start */

.problem-links {
  padding: 20px 30px 20px 30px;
  background-color: #ecf5ff;
}

.active-content h5 {
  background-color: #1870bb;
  padding: 14px;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
  z-index: 99999;
}

.problem-right-bg {
  border: 1px solid rgb(180, 180, 180);
  border-radius: 10px 10px 0px 0px;
}

.active-content {
  border: 1px solid rgb(180, 180, 180);
  height: 100%;
  border-radius: 10px 10px 0px 0px;
}
.problems-bg-add {
  background-color: #1870bb;
  padding: 10px;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}

.problem-search-feild .input-group {
  width: 100%;
}

.problem-search-feild .input-group .form-control {
  border: 1px solid #c7c8c9;
  padding: 10px;
  border-radius: 10px;
}

.problem-content .nav-link {
  margin-right: 10px;
}

.no-arrow-dropdown:after {
  display: none;
}

.accordion-button::after {
  background-image: url(../../../assets/images/collapse-arrow.svg);
  background-size: 10px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../../../assets/images/collapse-arrow.svg);
  background-size: 10px;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #307bc4;
}

.patient-detail-tabs .nav-tabs .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  height: 100%;
}
.patient-detail-tabs .nav-tabs li .fa-times {
  background-color: #ba2748;
  height: 16px;
  width: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-size: 12px;
  border-radius: 3px;
}
.btn-outline-success {
  color: #000;
  background-color: #c8e3ff;
  border-color: #0f3258;
  border: none;
  padding: 8px;
}

.btn-success:active {
  background-color: #0f3258 !important;
}

.btn-outline-success {
  --bs-btn-active-bg: #0f3258;
}

/* .btn-outline-success:active{
    --bs-btn-active-bg: #0f3258!important;
} */

:not(.btn-check) + .btn:active {
  --bs-btn-active-bg: #0f3258 !important;
}

.btn-success {
  --bs-btn-active-bg: #0f3258;
}

.btn-outline-success:hover {
  background-color: #0f3258;
}

.btn-success:hover {
  background-color: #0f3258;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0f3258;
  border: none;
  padding: 8px;
}

.patient-detail-tabs .nav-link {
  background-color: #c8e3ff;
}

.form-check-label {
  font-size: 12px;
}
.patient-detail-tabs .nav-tabs .nav-link.active .fa-times {
  background-color: white;
  color: #002548;
}

.btn-primary {
  color: #fff;
  background-color: #14457b;
  border-color: #0f3258;
}
.btn-discharge {
  color: #fff;
  background-color: #2bb32b;
  border-color: #2bb32b;
}
.btn-discharge:hover {
  color: #fff;
  background-color: #57f557;
  border-color: #57f557;
}
.btn-visit {
  color: #fff;
  background-color: #54a4f8;
  border-color: #54a4f8;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2490eb;
  border-color: #1870bb;
}

.problem-right-bg ul li a {
  color: #fff;
  font-size: 14px;
}

.add-problem-link a {
  color: #fff;
  font-size: 14px;
}
.form-diagnosis {
  width: 90%;
}

.main-div {
  max-height: 85vh; /* Adjust the height as needed */
  overflow: auto;
  background-color: #fff;
  padding: 5px 20px 20px 20px;
}

@media (max-width: 1400px) {
  .main-div {
    max-height: 80vh; /* Adjust the height as needed */
    overflow: auto;
    background-color: #fff;
  }
}
/* @media (max-width: 820px) {
    .main-div {
        max-height: 60vh !important;
        overflow: auto;
    }
} */

.diagnosis-btn .btn-secondary {
  background-color: #e0eefc;
  color: #000;
  margin-top: 6px;
  border-radius: 10px;
  padding: 10px;
  width: 130px;
  font-size: 14px;
}

/* problem tab close */
/*demographics tab start*/
.demo-bg {
  padding: 10px 30px 10px 30px;
  background-color: #ecf5ff;
}

.demo-main-patient {
  background-color: #ecf5ff;

  border-radius: 0px 0px 10px 10px;
  padding: 20px;
}

.demo-personal-info .form-control {
  height: 65px;
  border: none;
  background-color: #fbfbfb;
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.demo-personal-info {
  background-color: #fff;
  padding: 20px 20px;
  border-radius: 10px;
}

.demo-bg .nav-tabs .nav-link {
  background-color: transparent;
  border: 1px solid #4d4d4d;
  padding: 10px;
  margin-right: 40px;
  border-radius: 10px;
  width: 150px;
  font-size: 12px;
}

.demo-bg .nav-tabs .nav-link.active {
  background-color: #14457b;
  color: white;
}

.demo-bg .nav-tabs {
  border-bottom: none;
}
/*demographics tab close*/
/* insurance tab start */

.insurance-heading {
  padding: 15px 10px 15px 10px;
}
.insurance-heading h5 {
  color: #000;
  font-size: 16px;
}

h5 {
  margin: 0 !important;
}

.insurance-heading ul li a {
  text-decoration: underline;
  color: white;
}

.insurance-data {
  min-height: 250px;
}

.insurance-data ul li a {
  color: #ae4f4d;
  font-weight: 500;
  font-size: 14px;
}

.input-group-prepend i {
  font-size: 1rem;
}

.insurance-search .form-floating {
  width: 92%;
  border-radius: 0px 0px 10px 10px;
}

.insurance-search .form-control {
  border: 1px solid #686868;
}
.insurance-search .input-group-text {
  border: 1px solid #686868;
  background-color: #ffffff;
  border-right: none;
}
.insurance-p {
  padding: 20px 30px 20px 30px;
  background-color: #ecf5ff;
}
.border-bottom {
  border-bottom: 1px solid #686868 !important;
}

.insurance-table thead {
  color: #686868;
}
/* insurance tab close */
/* Contact tab start */
.content-heading {
  border-radius: 16px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.content-heading h6 a {
  font-weight: 600;
  color: #307bc4;
  font-size: 14px;
}
.contact-link li a {
  color: #ff0000 !important;
  padding: 20px;
  font-size: 12px;
}

.accordion-item {
  font-size: 12px;
}

.accordion-button {
  font-size: 14px;
  padding: 0.6rem 1.25rem;
}
.accordion-button:focus {
  box-shadow: none;
}

.contact-feilds {
  padding: 20px;
}

/* Contact tab Close */

/* setting-tab-start */

.setting-bg {
  background-color: #ffffff;
  padding: 20px 10px 20px 10px;
  border-radius: 10px;
  height: 116vh;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.nav-pills .nav-link {
  font-size: 14px;
}

.setting-bg .nav-link {
  text-align: left;
  border-bottom: 1px solid grey;
  color: rgb(0, 0, 0);
  background-color: #c8e3ff;
}

.map-content .form-control {
  border: none;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.map-content h5 {
  margin: 0;
}

.room-bg-content {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}
.map-bg {
  padding: 10px 20px 10px 20px;
  background-color: #307bc4;
  border-radius: 10px 10px 0px 0px;
}
.map-bg h5 {
  color: #fff;
}
.map-border {
  border: 1px solid #000;
  border-top-color: transparent;
  border-radius: 0px 0px 10px 10px;
  padding: 10px;
}

.preview-links {
  padding: 15px 20px 15px 20px;
  background-color: #307bc4;
  border-radius: 10px;
}
.preview-links .nav-tabs .nav-link {
  background-color: transparent;
  border: 1px solid #4d4d4d;
  padding: 10px;
  margin-right: 15px;
  border-radius: 10px;
  width: 120px;

  height: auto;
}

.facilty-links .nav-item .nav-link {
  background-color: #a6cffa;
  color: black;
  margin-right: 20px;
}

.facilty-links .nav-tabs .nav-link.active {
  background-color: #14457b;
  color: #fff;
}

.facilty-links .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.myprofile-bg {
  padding: 20px;
  background-color: #ecf5ff;
  border-radius: 10px;
}

@media (max-height: 992px) {
  .myprofile-bg {
    padding: 20px;
    background-color: #ecf5ff;
    border-radius: 10px;
  }
}

.myprofile-bg .nav-item .nav-link {
  background-color: #c8e3ff;
  margin-right: 30px;
padding: 10px;
  width: 120px;
  color: #000;
  border-radius: 10px;
}

.myprofile-bg .nav-item .nav-link.active {
  background-color: #14457b;
  color: #fff;
}

.myprofile-bg h5 {
  font-family: 600;
  color: #000;
  margin-bottom: 20px;
}
.accordion {
  --bs-accordion-btn-icon-transform: rotate(-90deg);
}
.electric-forms {
  padding: 20px;
  background-color: #ddecfc;
  border-radius: 10px;
}

.electric-btn .btn-electric {
  border: 1px solid #686868;
  border-radius: 6px;
  padding: 8px;
}

.electric-btn .btn-electric:hover {
  background-color: #14457b;
  color: #fff;
}

.security-content {
  padding: 20px;
  background-color: #ecf5ff;
  border-radius: 10px;
}

.security-content h5 {
  font-weight: 600;
}

.password-forms {
  padding: 20px;
  background-color: #ecf5ff;
  border-radius: 10px;
}

.password-forms h5 {
  font-weight: 600;
}

.btn-close {
  border: 0;
  border-radius: 0.25rem;
  opacity: 1;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #14457b;
}

.contact-bg-sec {
  background-color: #ecf5ff;
  padding: 20px 20px 20px 20px;
}

.active-bg-links {
  background-color: #ecf5ff;
  padding: 5px 10px 20px 10px;
  border-radius: 0px 0px 10px 10px;
}

.map-bg-sec {
  background-color: #ecf5ff;
  padding: 20px;
}

.floor-1-bg {
  background-color: #ecf5ff;
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
}

.bg-contact {
  background-color: #307bc4;
  border-radius: 8px 8px 0px 0px;
  z-index: 9999;
  color: #fff;
}

.w-300 {
  width: 300px;
}

.page-heading {
  margin-top: 10px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 10px;
  background-color: #ecf5ff;
}

.page-heading h5 {
  font-size: 1rem;
  display: inline-block;
  border-bottom: 2px solid #14457b;
  padding-bottom: 14px;
}

/*custom scroll*/
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

.bed-mapping-links {
  background-color: #c8e3ff;
  padding: 20px;
  border-radius: 10px;
}
.bed-mapping-links .nav-tabs .nav-link {
  margin-right: 20px;
  padding: 15px;
  width: 160px;
  border: 1px solid black;
  border-radius: 10px;
}

.bed-mapping-links .nav-tabs .nav-link.active {
  background-color: #14457b;
  color: #fff;
  border-bottom: 2px solid #2490eb;
}

.bed-mapping-headings {
  background-color: #1870bb;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  color: white;
}

.popover-header {
  background-color: #1870bb;
  padding: 10px;
  color: white;
}

.bs-popover-bottom > .popover-arrow::after {
  border-bottom-color: #1870bb;
}

.bed-user-detail h5,
p {
  color: #857878;
  margin: 0px;
}

.bed-user-detail h5 {
  color: #000;
}

.bed-user-detail p span {
  color: #000;
}

.bs-popover-bottom .popover-header::before {
  border-bottom: 1px solid #1870bb;
}

.popover-body {
  background-color: #e9e9e9;
  padding: 20px;
}

.bed-user-img img {
  border: 1px solid black;
  border-radius: 5px;
}

.bed-icon {
  font-size: 30px;
}

.bed-btn .btn-success {
  background: #1870bb;
  width: 80px;
}

.bed-maping-bg {
  background-color: #fff;
  padding: 20px;
}

.bed-two-btn .btn-success {
  background-color: #a6cffa;
  color: #000;
  width: 80px;
}

.bed-two-btn .bed-icon {
  color: #b89a00;
}

.bed-three-btn .btn-success {
  background-color: #a6cffa;
  color: #000;
  width: 80px;
}

.bed-three-btn .bed-icon {
  color: #ff0000;
}

.bed-four-btn .btn-success {
  background-color: #a6cffa;
  color: #000;
  width: 80px;
}

.bed-four-btn .bed-icon {
  color: #36b413;
}
/* allergy-tab-start */
.Allergy-heading {
  background-color: #ecf5ff;
  padding: 20px 30px 20px 30px;
}

.Allergies-links {
  padding: 15px;
  background-color: #1870bb;
}

.Allergies-links .form-check-label {
  color: #fff;
}

.btn-encounters-links .form-control {
  height: 60px;
}

.allergy-table {
  padding: 15px;
  border-bottom: 1px solid rgb(128, 128, 128);
}

.allergy-table table {
  box-shadow: none;
}

.allergy-table {
  background-color: #ecf5ff;
}

.allergy-table thead {
  color: #3d3d3d;
}

.allery-header {
  padding: 10px;

  background-color: #307bc4;
}

.allergy-model-content {
  width: 750px;
}

.allergy-body {
  padding: 20px;
}

.allergy-footer-btn {
  background-color: #fff;
  border-top: none;
}

.form-check-input:checked {
  background-color: #14457b;
}
/* allergy-tab-close */

/* encounter-tab start */

.encounter-heading {
  padding: 20px;
  background-color: #ecf5ff;
}

.encounter-search {
  color: #1870bb;
}

.encounter-links {
  padding: 20px;
  background-color: #ecf5ff;
  /* overflow: auto;
   max-height: 380px; */
}

.table {
  box-shadow: none;
}

.encounter-table th {
  color: #fff !important;
}

.table > :not(caption) > * > * {
  background: inherit;
}

.encounter-table thead {
  border-bottom: none;

  background-color: #307bc4;
}

.encounter-table tbody {
  padding: 20px;
  background-color: #ecf5ff;
}

.btn-draft {
  background-color: #9e7918;
  padding: 5px 15px 5px 15px;
  color: #fff;
  border-radius: 4px;
  width: 5rem;
}
.btn-draft:hover {
  background-color: #88691c;
  color: #fff;
}

.btn-disabled {
  background-color: #28a745 !important;
  color: white !important; /* Change text color if needed */
  width: 5rem;
}
.btn-assign {
  background-color: #307bc4;
  padding: 5px 7px 5px 7px;
  color: #fff;
  border-radius: 4px;
}
.fa-trash {
  transition: 0.3s ease;
}
.fa-trash:hover {
  font-size: 15px;
  transition: 0.3s ease;
}
.btn-create {
  background-color: #c9c9c9;
  padding: 5px 15px 5px 15px;
  color: #000;
  border-radius: 4px;
}

.encounter-lable .form-check-label {
  color: #1758ff;
}

.encounter-search {
  height: 40px;
}

p {
  font-size: 12px;
}

/* encounter-tab close */

/* add visit reason start */

.visit-border-left {
  border-left: 1px solid rgb(134, 134, 134);
  padding-left: 10px;
  height: 100%;
  min-height: 72px;
}

.visit-info p {
  font-size: 12px;
  font-weight: 500;
}

.patient-header {
  background-color: #ecf5ff;
  padding: 10px;
}
.import-past {
  color: #1768b8;
}

.visit-reason-links {
  padding: 10px;
  background-color: #307bc4;
  border-radius: 6px;
}

.visit-reason-links h5 {
  color: #fff;
}

.visit-reason-links .fa-circle {
  color: #61ff00;
}

.visit-reason-links .fa-server {
  color: #fff;
}

.visit-text-area {
  /* overflow: auto;
    max-height: 100vh; */
  background-color: #ecf5ff;
  padding: 10px;
}

.visit-right-links {
  background-color: #307bc4;
  height: 100%;
  border-radius: 6px 6px 0px 0px;
}

.visit-right-links li a {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.visit-right-links li {
  border-bottom: 1px solid #e4e4e4;
  padding: 10px;
}

.chief-complaint .form-control {
  box-shadow: none;
  height: 150px !important;
}

/* @media only screen and (max-width: 1200px) {

    .visit-left-nav{
        width: 280px!important;
    } 


}

@media only screen and (max-width: 1024px) {

    .visit-left-nav{
        width: 240px!important;
    } 


} */

.visit-main-left {
  background-color: #ecf5ff;

  border-radius: 0px 30px 0px 0px;
}

/* add visit reason end */

/*notes tab  start */
.notes-content {
  padding: 20px;
  background-color: #ecf5ff;
}

.notes-tables {
  background-color: #ecf5ff;
  padding: 20px;
  border-radius: 10px;
}

/*notes tab end */

/*document tab  start */

.document-content {
  padding: 20px 20px 20px 0px;
  background-color: #ecf5ff;
}

.document-content .avatar .all-doc {
  color: #000;
}

.document-content .drop-icon {
  color: #000;
}

.document-content .form-control {
  border: 1px solid #6d6d6d;
}

.document-table {
  padding: 20px 20px 20px 20px;
  background-color: #ecf5ff;
}

.document-table th {
  padding-bottom: 20px;
}

.accordion-body .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #14457b;
  border-radius: 6px;
  width: 100%;
}

.accordion-body .nav-tabs .nav-link {
  text-align: start;
  width: 100%;
}

.accordion-body .nav-tabs .nav-link:hover {
  background-color: #1768b8;
  color: #fff;
}

/* add visit reason start */

.visit-border-left {
  border-left: 1px solid rgb(134, 134, 134);
  padding-left: 10px;
  height: 100%;
  min-height: 72px;
}

.visit-info p {
  font-size: 12px;
  font-weight: 500;
}

.patient-header {
  background-color: #ecf5ff;
  padding: 10px;
}
.import-past {
  color: #1768b8;
}

.visit-reason-links {
  padding: 10px;
  background-color: #307bc4;
  border-radius: 6px;
}

.visit-reason-links h5 {
  color: #fff;
}

.visit-reason-links .fa-circle {
  color: #61ff00;
}

.visit-reason-links .fa-server {
  color: #fff;
}

.visit-text-area {
  /* overflow: auto;
    max-height: 100vh; */
  background-color: #ecf5ff;
  padding: 10px;
}

.visit-right-links {
  background-color: #307bc4;
  height: 100%;
  border-radius: 6px 6px 0px 0px;
}

.visit-right-links li a {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.visit-right-links li {
  border-bottom: 1px solid #e4e4e4;
  padding: 10px;
}

.chief-complaint .form-control {
  box-shadow: none;
  height: 80px;
}

/* @media only screen and (max-width: 1200px) {

    .visit-left-nav{
        width: 280px!important;
    } 


}

@media only screen and (max-width: 1024px) {

    .visit-left-nav{
        width: 240px!important;
    } 


} */

.visit-main-left {
  background-color: #ecf5ff;

  border-radius: 0px 30px 0px 0px;
}
/* add-vital-start */
.vital-heading {
  background-color: #ecf5ff;
  padding: 10px;
}

.vital-bg {
  background-color: #ecf5ff;
  padding: 5px 20px 10px 20px;
}

.current-vital {
  background-color: #ecf5ff;
  padding: 10px;
  color: #000;
  border-radius: 10px 10px 0px 0px;
}

.vital-cards {
  background-color: #ecf5ff;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.vital-cards a {
  color: #000;
}

.vital-border {
  border: 1px solid grey;
}
.vital-content ul {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  padding: 20px;
}
.vital-content ul {
  height: 60px;
}

.vital-content ul li {
  margin-bottom: 5px;
}
.vital-content {
  width: 25%;
}

.vital-table-headings {
  padding: 10px;
  background-color: #1768b8;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}

.vital-tbody {
  background-color: #ecf5ff;
  padding: 20px;
}

.vital-table-headings th {
  padding-left: 20px;
}
.vital-tbody td {
  padding-left: 20px;
}

.vital-content .fw-bold {
  color: #3f3f3f;
}
/* .vital-tab-end */

.all-vital {
  color: #1768b8;
}

.vital-cards-form .form-control {
  background-color: #fff;
  border-radius: 6px !important;
}
.vital-bg .form-control {
  background-color: #f9f9f9;
}

.vital-heading-bg {
  padding: 10px;
  background-color: #1768b8;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}

.vital-forms-bg {
  background-color: #ecf5ff;
  padding: 20px;
}
.vital-cards-form .form-select {
  background-color: #fff;
  border-radius: 6px !important;
}

/* add-vital-end */
.blur-background {
  filter: blur(5px); /* Adjust the blur intensity as needed */
  pointer-events: none; /* Prevent interacting with elements behind the blurred area */
}
/* Loading.css */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 1rem;
}
.problem-anchors ul:hover {
  background-color: #dbecfd; /* Change the background color on hover */
  cursor: pointer; /* Change cursor to pointer on hover */
}
/* addmission tab  */
.admission-heading {
  padding: 10px;
  background-color: #1768b8;
  color: #fff;
}

.admittion-bg {
  background-color: #67aaee;

  padding: 20px;
}

.addmission-icons li {
  margin-right: 10px;
}
.wound-height input {
  border: none;
  border-bottom: 1px solid #000;
  background: transparent;
  font-size: 12px;
  width: 100%;
}

.wound-height h5 {
  margin-right: 5px !important;
}
.vital-bg-two-form {
  background-color: #fff;
  border-radius: 10px;
  padding-top: 10px;
  overflow: hidden;
}
.visit-text-area-bg {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  overflow: auto;
  height: 200px;
  font-size: 13px;
}

.edit-reason {
  opacity: 100%;
}

.visit-text-area-bg:hover .edit-reason {
  /* opacity: 100%; */
  cursor: pointer;
}
.visit-text-area-bg:hover {
  cursor: pointer;
}

.btn-danger-visit {
  background-color: #2490eb;
}

.visit-btn-wound:hover .fa-visit-icon {
  font-size: 16px !important;
}

.wound-class-bg table {
  box-shadow: none !important;
}

.wound-class-bg table td {
  border-bottom: none !important;
  font-size: 12px;
}

.wound-class-bg table th {
  width: 45%;
  font-weight: 500;
  font-size: 12px;
}

.image-wound-width th {
  width: 50% !important;
}
.psychiatric-headings h5 {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
}
.psychiatric-mental-headings h5 {
  font-weight: 700;
  font-size: 14px;
}
.chek-margin-left {
  margin-left: 5px;
  font-size: 12px;
}
.psychiatric-height table {
  box-shadow: none;
}
.psychiatric-height td {
  border-bottom: none;
}
.psychiatric-height input {
  border: none;
  border-bottom: 1px solid #000;
  background: transparent;
  font-size: 12px;
  width: 100px;
}
.psychiatric {
  font-size: 12px;
}

.psychiatric-bg .row > * {
  margin-top: 7px;
}

.datail-p-left {
  width: 40%;
  float: left;
}

.datail-p-right {
  width: 40%;
  float: right;
}
.detail-flex {
  display: flex;
}

.btn-map-wound {
  padding: 5px;
}
.wound-b-n table {
  box-shadow: none;
}
.wound-b-n td {
  border-bottom: none;
}
.wound-b-n th {
  font-weight: 500;
  font-size: 12px;
}

.p-datepicker {
  z-index: 9999 !important;
}

/* .p-float-label:has(.p-inputwrapper-filled) label {
    top: 12px;
    font-size: 12px;
  } */

/* Add this to your CSS file */

.user-img img:hover {
  border: 1px solid #000;
}

.admittion-bg-ff {
  padding: 20px;
  background-color: #ecf5ff;
}
.admittion-bg-ff .form-control {
  height: 150px;
}
.btn-vacant {
  background-color: #307bc4;
  color: #fff;
  width: 30%;
  border-radius: 8px;
}
.btn-assign {
  background-color: #8e928f;
  color: #fff;
  width: 100%;
  border-radius: 10px;
  margin-top: 5px;
}
.btn-vacant:hover {
  background-color: #79b2ec;
  color: #fff;
}
.custom-modal-width {
    max-width: 70%; /* Adjust this value as needed */
    width: 70%; /* Ensures the modal expands to this width */
  }

  .admittion-bg-ff h4{
font-size: 12px;
  }

  .lable-ff{
    font-size: 12px;
  }

  .p-inputtext{
font-size: 12px;
  }
  .btn-danger{
   font-size: 14px;
  }

.btn-danger:hover .header-icon-ff{
    color: #fff;
  }
  .p-inputtext{
    font-size: 12px !important;
  }

  .p-float-label label{
    font-size: 12px;
  }
  .accordion-button{
    font-size: 13px;
  }
  th{
  font-size: 12px;
  }
  td{
   font-size: 12px;
  }
  .p-calendar{
    height: 40px;
  }

  .css-jp011u-MuiTablePagination-root{
    padding-left: 0px;
    padding-right: 0px;
  }
  .fc .fc-button-group {
    font-size: 13px;
    margin-left: 10px;
  }
  button.fc-today-button.fc-button.fc-button-primary{
    font-size: 13px;
  }
  .fc-button-group{
margin-right: 10px;
  }
  .fc-view{
    margin-left: 10px;
    margin-right: 10px;
  }
  /* .form-floating>.form-control:not(:placeholder-shown)~label:after{
    background-color: #fff !important;
    width: 100%;
  } */
