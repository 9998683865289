.bed-mapping-links {
  background-color: #c8e3ff;
  padding: 20px;
  border-radius: 10px;
}
.bed-mapping-links .nav-tabs .nav-link {
  margin-right: 20px;
  padding: 15px;
  width: 160px;
  border: 1px solid black;
  border-radius: 10px;
}
/* Header.css */
.search-results {
  position: absolute;
  background-color: white;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 20%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  z-index: 1000;
  right: 12px;
  top: 138px;
}

.search-results li {
  padding: 10px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #f0f0f0;
}

.search-diagnosis {
  /* position: absolute; */
  background-color: white;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  z-index: 1000;
  right: 12px;
  top: 138px;
}

.search-diagnosis li {
  padding: 10px;
  cursor: pointer;
}

.search-diagnosis li:hover {
  background-color: #f0f0f0;
}
.bed-mapping-links .nav-tabs .nav-link.active {
  background-color: #14457b;
  color: #fff;

  border-bottom: 2px solid #2490eb;
}

.bed-mapping-headings {
  background-color: #1870bb;

  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  color: white;
}
.mapping-scroll {
  overflow: auto;
  height: 85vh;
}
.bed-user-name {
  transition: 0.3s ease;
}
.form-switch .form-check-input {
  width: 2.5rem;
}
.form-check-input {
  height: 1rem;
}
.bed-user-name:hover {
  background-color: #1870bb;
  color: #fff;
  border-radius: 5px;
  transition: 0.3s ease;
}
.popover-header {
  background-color: #1870bb;
  padding: 10px;
  color: white;
}
.alert {
  padding: 0px !important;
  border: none;
}
.card:hover {
  box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.form-check-input {
  border: 1px solid #1870bb;
}

.bs-popover-bottom > .popover-arrow::after {
  border-bottom-color: #1870bb;
}
.alert-bed {
  position: fixed;
  top: 5%;
  left: 50%;
}

.bed-user-detail h5,
p {
  color: #857878;
  margin: 0px;
}

.bed-user-detail h5 {
  color: #000;
}

.bed-user-detail p span {
  color: #000;
}

.bs-popover-bottom .popover-header::before {
  border-bottom: 1px solid #1870bb;
}

.popover-body {
  background-color: #e9e9e9;
  padding: 20px;
}

.bed-user-img img {
  border: 1px solid black;
  border-radius: 5px;
}

.bed-icon {
  font-size: 30px;
}

.bed-btn .btn-success {
  background: #1870bb;
  width: 80px;
}

.bed-maping-bg {
  background-color: #fff;
  padding: 20px;
}

.bed-two-btn .btn-success {
  background-color: #a6cffa;
  color: #000;
  width: 80px;
}

.bed-two-btn .bed-icon {
  color: #b89a00;
}

.bed-three-btn .btn-success {
  background-color: #a6cffa;
  color: #000;
  width: 80px;
}

.bed-three-btn .bed-icon {
  color: #ff0000;
}

.bed-four-btn .btn-success {
  background-color: #a6cffa;
  color: #000;
  width: 80px;
}

.bed-four-btn .bed-icon {
  color: #36b413;
}
/* .patient-list{
   
    height: 90vh;
    overflow: auto;

} */

/* @media  (max-width:1400px) {
  .patient-list{
   
    height: 86vh !important;
    overflow: auto;

}
} */
.bg-patient-tab {
  background-color: #f3f1f1;
  padding: 3px 10px 7px 10px;
}
.login-inputs .input-group-text {
  height: 40px;
  border: none;
  background-color: #2490eb;
  padding: 0px 20px 0px 20px;
  font-size: 20px;
  z-index: 9999;
}
.input-search {
  width: 20%;
}
.form-control {
  position: relative;
}

.patient-info th {
  background-color: #14457b;
  color: #fff;
}

.patient-info tr:nth-child(even) {
  background-color: #ecf5ff;
}

.patient-info tr:nth-child(odd) {
  background-color: #c8e3ff;
}

table {
  border: 1px #a39485 solid;
  font-size: 0.9em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}

th {
  text-align: left;
  font-size: 14px;
}

thead {
  font-weight: bold;
}

td,
th {
  padding: 1em 0.5em;
  vertical-align: middle;
}

td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
}

a {
  color: #73685d;
  font-size: 12px;
}

table {
  position: relative;
  padding-bottom: 0;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

thead {
  white-space: nowrap;
}

.user-img {
  width: 40px;
  height: 40px;
}

.patient-info th {
  background-color: #14457b;
  color: #fff;
}

.patient-info tr:nth-child(even) {
  background-color: #ecf5ff;
}

.patient-info tr:nth-child(odd) {
  background-color: #c8e3ff;
}

.modal-header {
  background-color: #307bc4;
  color: white;
}

.all-patient-new-tab .btn--outline-success:hover {
  color: #fff;
}
.nav-tabs {
  border-bottom: inherit;
}
.fixed-all-p{
  position: sticky;
  position: -webkit-sticky;
  top: 0;

}
.patient-info{
    position: relative;
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
background-color: #d6d6d6;
}

.p-user-img-h img:hover{
  border: 1px solid #000;
}

.fixed-sticky thead th{
  position: sticky;
  top:0;
  width: 100%;
 
}

.css-xn82ks-MuiTable-root {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  overflow: visible;
}
.w-patient-add{
  width: 300px;
  margin-left: 20px;
}