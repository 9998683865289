
.container-warning-patient{
    width: 650px;
    margin: auto;
    padding: 30px;
        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
   /* background-color: #d0e2ff; */
   height: 100%;
}
.container-warning p{
  margin: 10px 0px;
}


.soul-detail-bio{
    float: right;
    width: 40%;
    margin-top: 10px;
    margin-bottom: 30px;
}

.soul-detail-bio li{
    margin-bottom: 5px;

}
.img-brand-soul{
    float: left;
    width: 60%;
    margin-bottom: 30px;
}
.img-brand-soul img{
    width: 50%;
}
ul,li,a{
    list-style: none;
   margin: 0;
   padding: 0;
}
.clear-both{
    clear: both;
}

.user-name{
margin-left: 12px;
}

.content-left-soul{
    float: left;
    width: 60%;
    margin-bottom: 20px;
    
}

.content-right-soul{
    float: right;
    width: 40%;
    margin-bottom: 20px;  
}