
.container-warning{
    width: 650px;
    margin: auto;
    padding: 20px;
        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
   
}
.container-warning p{
  margin: 5px 0px;
}
.warning-content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.warning-content h2{
text-decoration: underline;
}
.container-warning .brand-logo-warning{
display: flex;
justify-content: center;
}


.brand-logo-warning img{
width: 50%;
}
.container-warning .input{
    border: none;
    border-bottom: 1px solid #000 !important;
}
:focus-visible {
    outline: none;
}