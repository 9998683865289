.modal-header{
    background-color: #307bc4;
    color: white;
}
.btn-close {
   
    border: 0;
    border-radius: .25rem;
     opacity: 1; 
}
.modal-body{
    padding: 0px;
       
}



.modal-footer{
    background-color: #307bc4;
    color: white;
}



.btn-danger:hover{
    background-color: #0f3258;
    
}